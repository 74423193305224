<template>
    <b-card>
      <div slot="header">
        <strong>Lista de Grupos</strong>
      </div>
      <div>
        <router-link :to="{ name: 'Criar Grupo', params: { id: this.$route.params.id } }" class="btn btn-primary">Criar Grupo</router-link></p>
      </div>
      <br>

      <table class="table" v-if="grupos.length > 0">
        <thead>
          <tr>
            <th>Mnemônico</th>
            <th>Descrição</th>
            <th>Data Criação</th>
            <th>Ativo</th>
            <th>Tipo</th>
            <th>Coordenador Areas</th>
            <th style="width: 140px;">Ações</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(gruposItem, index) in grupos" :key="index">
            <td> {{ gruposItem.mnemonico }} </td>
            <td> {{ gruposItem.descricao }} </td>
            <td> {{ gruposItem.dataCriacao }} </td>
            <td>
              <span v-if="gruposItem.ativo" class="badge badge-pill badge-success">Ativo</span>
              <span v-if="!gruposItem.ativo" class="badge badge-pill badge-danger">Inativo</span>
            </td>
            <td> {{ gruposItem.tipo }} </td>
            <td> {{ gruposItem.coordAreas }} </td>
            <td>
              <b-btn :to="{ name: 'Editar Grupo', params: { id: gruposItem.id } }" variant="success" size="sm">Editar</b-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      grupos: [],
      tipos: []
    }
  },
  created: function () {
    this.verificaSessao()
    this.grupos = []
  },
  methods: {
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        this.listarTipos()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'error',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    listarTipos: function () {
      this.listar('gp_Grupo_Tipo', 'gtipo_mnemonico').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.tipos[response[i].gtipo_ID] = response[i].gtipo_mnemonico
          }
          this.listarGrupos()
        },
        (response) => {}
      )
    },
    listarGrupos: function () {
      // Lista de grupos
      this.listar('gp_Grupo', 'grp_mnemonico', 'gtipo_ID = 1').then(
        (response) => {
          for (var i = 0; i < response.length; i++) {
            this.grupos.push({
              mnemonico: response[i].grp_mnemonico,
              descricao: response[i].grp_descricao,
              dataCriacao: moment(response[i].grp_data_criacao).format('DD/MM/YY'),
              ativo: response[i].grp_ativo,
              tipo: this.tipos[response[i].gtipo_ID],
              coordAreas: (response[i].grp_coord_areas ? 'SIM' : 'Não'),
              id: response[i].grp_ID
            })
          }
        }
      )
    }
  }
}
</script>

<style>

</style>
